import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import stores from './store'
import dayjs from 'dayjs'

import 'animate.css'
import '@/assets/element.scss'
import '@/utils/oa/mBoxTooltip.css'
import 'remixicon/fonts/remixicon.css'
import '@/utils/WangEditor/view.css'
import projectComponents from '@/components'
import { layout } from '@o2oa/component'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as RemixIcon from '@remixicon/vue'
import { commonMixins } from '@/common/commonMixins'
import AnimatedNumber from 'animated-number-vue3'
import 'echarts'
import ECharts from 'vue-echarts'
import '@/utils/jquery/jquery-1.8.3.min'
import '@/utils/vue-plugin-hiprint'
import directives from '@/directive'
import '@/utils/baidutongji'
import 'dayjs/locale/zh-cn'
import DataVVue3 from '@kjgl77/datav-vue3'

dayjs.locale('zh-cn')

const app = createApp(App)
layout.addReady(() => {
  app.use(stores.store)
  app.use(router)
  app.use(AnimatedNumber)
  app.use(DataVVue3)
  app.component('v-chart', ECharts)
  app.use(ElementPlus, {
    locale: zhCn,
    namespace: 'dubhe'
  })
  app.mixin(commonMixins)
  // 异步引入自定义组件
  for (const [key, component] of Object.entries(projectComponents)) {
    app.component(key, defineAsyncComponent(component))
  }
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  for (const [key, component] of Object.entries(RemixIcon)) {
    app.component(key, component)
  }
  for (const [key, directive] of Object.entries(directives)) {
    app.directive(key, directive)
  }
  app.mount('#app')
})
