import { defineStore } from 'pinia'
import { getLoginUserInfo, getPersonCustom, updatePersonCustom } from '@/axios'
import * as Thumbmarkjs from '@thumbmarkjs/thumbmarkjs'

export const useUserStore = defineStore({
  id: 'user',
  persist: true,
  state: () => {
    return {
      userInfo: {},
      newCode: '',
      lastSendCodeTime: null,
      fingerprintShow: false,
      fingerprintKeyMap: {
        other: 'fingerprint',
        table: 'tableFingerprint'
      }
    }
  },
  actions: {
    async initUserInfo() {
      await getLoginUserInfo()
        .then((res) => {
          if (res) {
            this.userInfo = res.data
          }
        })
        .catch(() => {})
    },
    async initBrowserInfo() {
      this.newCode = await Thumbmarkjs.getFingerprint()
      let fingerprintKey = await this.getFingerprintKey()
      getPersonCustom({ key: fingerprintKey }).then((res) => {
        let oldCode = []
        if (res.data) {
          oldCode = JSON.parse(res.data)
        }
        if (oldCode.length === 0) {
          this.updateBrowserInfo()
          this.fingerprintShow = false
        } else if (oldCode.includes(this.newCode)) {
          this.fingerprintShow = false
        } else {
          this.fingerprintShow = true
        }
      })
    },
    async getFingerprintKey() {
      let fingerprintData = await Thumbmarkjs.getFingerprintData()
      let fingerprintKey = this.fingerprintKeyMap.other
      if (/(?:Linux|iPad)/.test(fingerprintData.system.platform)) {
        fingerprintKey = this.fingerprintKeyMap.table
      }
      return fingerprintKey
    },
    async updateBrowserInfo() {
      let fingerprintKey = await this.getFingerprintKey()
      await updatePersonCustom({ key: fingerprintKey, params: [this.newCode] }).then((res) => {})
    },
    setSendCodeTime(time) {
      this.lastSendCodeTime = time
    }
  }
})
